export default {
  proxy_path: "https://ibhv77u1bi.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://7q6qmsyc37.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyCvHe11XoXXIdcqwKIRI9kivWPjAvNdaAQ",
  main_company_term_id: "6",
  crm_base_url: "https://crm.testing.newmont.forwoodsafety.com",
  forwood_id_url: "https://id.testing.newmont.forwoodsafety.com?redirect_uri=https://mapreport.testing.newmont.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.testing.newmont.forwoodsafety.com",
  Auth: {
    userPoolId: "us-west-2_4KPzdpyw8",
    userPoolWebClientId: "3407g7mndv078g0ek52daljf0s",
    cookieStorage: {
      domain: ".testing.newmont.forwoodsafety.com",
      secure: true
    }
  }
};
